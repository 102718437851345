import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/Layout';
import { Section } from '../components/Section';
import Container from '../components/Container';

const Content = styled.div`
  img {
    max-width: 100%;
  }
`;

export const PageTemplate = ({ title, content }) => (
  <Section>
    <Container>
      <h1 dangerouslySetInnerHTML={{ __html: title }} />
      <Content>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Content>
    </Container>
  </Section>
);

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
};

const Page = ({ data }) => {
  const { wordpressPage: page, site } = data;

  return (
    <Layout>
      <Helmet title={`${page.title} | ${site.siteMetadata.title}`} />
      <PageTemplate title={page.title} content={page.content} />
    </Layout>
  );
};

Page.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Page;

export const pageQuery = graphql`
  query PageById($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
